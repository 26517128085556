import { Request } from "./request"; //导入请求拦截器request
import Qs from "qs";

export function getUserlist(parameter: any) {
  //导出方法
  return Request.axiosInstance({
    url: "https://m.vocy.cn/hhz/public/index.php",
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: Qs.stringify(parameter),
  });
}

export function getUserlistFrom(parameter: any) {
  //导出方法
  return Request.axiosInstance({
    url: "https://m.vocy.cn/hhz/public/index.php",
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: Qs.stringify(parameter),
  });
}

export function getUserlisttype(parameter: any, type: any) {
  //导出方法
  return Request.axiosInstance({
    url: `https://m.vocy.cn/hhz/public/index.php?${type}`,
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: Qs.stringify(parameter),
  });
}
export function getUserlisttype1(parameter: any, type: any) {
  //导出方法
  return Request.axiosInstance({
    url: `https://m.vocy.cn/hhz/public/index.php?${type}`,
    method: "GET",
    data: Qs.stringify(parameter),
  });
}
export function getUserlisttype2(parameter: any, type: any) {
  //导出方法
  return Request.axiosInstance({
    url: `https://m.vocy.cn/hhz/public/index.php?${type}`,
    method: "POST",
    // headers: { "Content-Type": "multipart/form-data" },

    data: Qs.stringify(parameter),
  });
}

export function getUserlisttypeUrl(parameter: any, type: any, url: any) {
  //导出方法
  return Request.axiosInstance({
    url: url,
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: Qs.stringify(parameter),
  });
}

export function getShareConfig(parameter: any, type?: any, url?: any) {
  //导出方法
  return Request.axiosInstance({
    url:
      "https://m.vocy.cn/hhz/public/index.php?s=api/share/index&url=" +
      parameter.url,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    // data: Qs.stringify(parameter.url),
  });
}
