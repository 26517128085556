
import { defineComponent, reactive, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import wx from "weixin-js-sdk-ts";
import { getShareConfig } from "@/http/api"; //接口
import { showToast ,closeToast} from "vant";
export default defineComponent({
  name: "CButton",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "中国青少年广播影视网",
    },
    desc: {
      type: String,
      default: "点击查看详情",
    },
    icon: {
      type: String,
      default: window.location.origin + "/hhzcs/" + "icon.jpg",
    },
    imgUrl: {
      type: String,
      default: require("../../assets/common/share.png"),
    },
  },
  setup(props, ctx) {
    const state = reactive({
      showOverlay: false,
    });
    watch(
      () => props.show,
      (value) => {
        state.showOverlay = value;

        var shareData = {
          title: props.title,
          desc: props.desc,
          link: window.location.href,
          imgUrl: props.icon,
          //   imgUrl: require(""),
          success: () => {
            showToast("分享成功!");
          },
          cancel: () => {
            // showToast("分享成功!");
          },
        };
        console.log("shareData", shareData);

        wx.onMenuShareTimeline(shareData);
        wx.onMenuShareAppMessage(shareData);
        wx.onMenuShareQQ(shareData);
        wx.onMenuShareWeibo(shareData);
        wx.onMenuShareQZone(shareData);
      }
    );
    console.error('图片地址请使用require("")');
    const $router = useRouter();
    onMounted(() => {});
    function onClose() {
      // if (props.to) {
      //   $router.push({ path: props.to, query: props.id as unknown as LocationQueryRaw });
      // } else {
      state.showOverlay = false;
      ctx.emit("close");
      // }
    }
    wxShare();
    function wxShare() {
      var url = encodeURIComponent(window.location.href);
      console.log("url", url, window.location.href);

      getShareConfig({ url: url.replace(/\&/g, "%26") }).then((result) => {
        let data = result.data.data;
        console.log("分享获取的配置result", data);
        wx.config({
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: [
            "onMenuShareTimeline",
            "onMenuShareAppMessage",
            "onMenuShareQQ",
            "onMenuShareWeibo",
            "onMenuShareQZone",
          ],
          openTagList: [],
        });
        setTimeout(() => {
          var shareData = {
            title: props.title,
            desc: props.desc,
            link: window.location.href,
            imgUrl: props.icon,
            //   imgUrl: require(""),
            success: () => {
              showToast("分享成功!");
            },
            cancel: () => {
              // showToast("分享成功!");
            },
          };
          console.log("shareData", shareData);

          wx.onMenuShareTimeline(shareData);
          wx.onMenuShareAppMessage(shareData);
          wx.onMenuShareQQ(shareData);
          wx.onMenuShareWeibo(shareData);
          wx.onMenuShareQZone(shareData);
        }, 100);
      });
      wx.ready(() => {
        var shareData = {
          title: props.title,
          desc: props.desc,
          link: window.location.href,
          imgUrl: props.icon,
          //   imgUrl: require(""),
          success: () => {
            showToast("分享成功!");
          },
          cancel: () => {
            // showToast("分享成功!");
          },
        };
        console.log("shareData", shareData);

        wx.onMenuShareTimeline(shareData);
        wx.onMenuShareAppMessage(shareData);
        wx.onMenuShareQQ(shareData);
        wx.onMenuShareWeibo(shareData);
        wx.onMenuShareQZone(shareData);
      });
    }
    return {
      state,
      onClose,
      //   wxShare,
    };
  },
  methods: {},
  mounted() {},
});
