import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vant from "vant";
import "vant/lib/index.css";
import '@/style/custom-vars.scss'; // 或者引入自定义变量文件
// import '@/style/scrollbar.scss'; // 或者引入自定义变量文件


import { Request } from "@/http/request";
import VueAxios from "vue-axios";
import "animate.css/animate.min.css";
import Copyright from "@/components/common/Copyright.vue";
import CShare from "@/components/common/CShare.vue";
//引入//main.ts
import 'windi.css'

// import 'virtual:windi.css'

// import 'windi.css'
// import 'virtual:windi.css'
// import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";
// import Equal from "equal-vue";
// import "equal-vue/dist/style.css";
// App.prototype.$axios = request;
// import "swiper/swiper-bundle.css";
// swiper.less/sass/css 决定了基础的样式
// import "@swiper/swiper.min.css";
import { getCurrentInstance } from "vue";
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
const internalInstance: any = getCurrentInstance(); // 有效
import VueParticles from "vue-particles";
import infiniteScroll from "vue-infinite-scroll";
import Vue3DraggableResizable from "vue3-draggable-resizable";
//需引入默认样式
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css";

import 'swiper/css'

// import "@/assets/swiper-bundle.css";
// swiper.less/sass/css 决定了基础的样式
// import "@/assets/swiper.min.css";
// import '@/libs/rem.ts'

// import { createApp } from 'vue'
// import Vconsole from "vconsole";
// let vConsole: any = new Vconsole();

createApp(App)
  .component("Copyright", Copyright)
  .component("CShare", CShare)
  // .use(vConsole)
  .use(store)
  .use(router)
  // .use(ElementPlus)
  .use(VueParticles)
  .use(vant)
  .use(Vue3DraggableResizable)
  // .use(ElementPlus)
  // .use(vConsole)
  .use(VueAxios, Request.init())
  .use(infiniteScroll)
  .mount("#app");

type AType = "a" | "b" | "c";
type AAA<T extends string> = T extends [...any, infer a] ? a : "";

type aa = AAA<AType>;
