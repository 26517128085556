import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store";

// const vuexStore:any = useStore();
const routes: Array<RouteRecordRaw> = [
  {
    path: "/Index",
    name: "home",
    component: HomeView,
    meta: {
      keepAlive: true,
      keepAliveKey: 1,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 2,
    },
  },
  {
    path: "/phone",
    name: "phone",
    component: () => import("../views/PhoneLogin.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 3,
    },
  },
  {
    path: "/phone1",
    name: "phone1",
    component: () => import("../views/PhoneLogin1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 300,
    },
  },
  {
    path: "/case",
    name: "case",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AboutView.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 4,
    },
  },
  {
    path: "/caseinfo",
    name: "caseinfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Info.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 5,
      keepTop: true,
    },
  },
  {
    path: "/Velue",
    name: "Velue",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Velue.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 6,
    },
  },
  {
    path: "/LoginNext1",
    name: "LoginNext1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LoginNext1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 7,
    },
  },
  {
    path: "/LoginNext",
    name: "LoginNext",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LoginNext.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 8,
      keepTop: true,
    },
  },
  {
    path: "/LoginNext2",
    name: "LoginNext2",
    meta: {
      isKeepAlive: false,
      keepAliveKey: 9,
      keepTop: true,
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LoginNext2.vue"),
  },
  {
    path: "/LoginNext3",
    name: "LoginNext3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LoginNext3.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 10,
      keepTop: true,
    },
  },
  {
    path: "/LoginNext4",
    name: "LoginNext4",
    meta: {
      isKeepAlive: false,
      keepAliveKey: 11,
      keepTop: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () => import("../views/LoginNext4.vue"),
  },
  {
    path: "/ShiJian",
    name: "ShiJian",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ShiJian.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 12,
    },
  },
  {
    path: "/ydAct",
    name: "ydAct",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ydAct.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 13,
    },
  },
  {
    path: "/",
    name: "Index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Index.vue"),
    meta: {
      isKeepAlive: true,
      keepAlive: true, //是否缓存
      keepAliveKey: 14,
    },
  },
  {
    path: "/Map",
    name: "Map",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Map.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 15,
    },
  },
  {
    path: "/Information",
    name: "Information",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Information.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 16,
    },
  },
  {
    path: "/InterInfo",
    name: "InterInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/InterInfo.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 17,
      keepTop: true,
    },
  },
  {
    path: "/Activity",
    name: "Activity",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Activity.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 18,
    },
  },
  {
    path: "/ActInfo",
    name: "ActInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ActInfo.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 19,
      keepTop: true,
    },
  },
  {
    path: "/ActBaoming",
    name: "ActBaoming",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ActBaoming.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 20,
    },
  },
  {
    path: "/ActBaomingMore",
    name: "ActBaomingMore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ActBaomingMore.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 201,
    },
  },
  {
    path: "/Yingdi",
    name: "Yingdi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Yingdi.vue"),
    meta: {
      isKeepAlive: false,
      keepAliveKey: 21,
    },
  },
  {
    path: "/YingdiInfo",
    name: "YingdiInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/YingdiInfo.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 22,
      keepTop: true,
    },
  },
  {
    path: "/YingdiLook",
    name: "YingdiLook",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/YingdiLook.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 23,
    },
  },
  {
    path: "/My",
    name: "My",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/My.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 24,
    },
  },
  {
    path: "/MyCollet",
    name: "MyCollet",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/myCollet.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 25,
    },
  },
  {
    path: "/Mygz",
    name: "Mygz",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/Mygz.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 26,
    },
  },
  {
    path: "/MyLook",
    name: "MyLook",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/MyLook.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 27,
    },
  },
  {
    path: "/MyAct",
    name: "MyAct",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/myact.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 28,
    },
  },
  {
    path: "/Lianbo",
    name: "Lianbo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Lianbo.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 29,
    },
  },
  {
    path: "/LianboInfo",
    name: "LianboInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LianboInfo.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 30,
      keepTop: true,
    },
  },
  {
    path: "/MyInfo",
    name: "MyInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/MyInfo.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 31,
    },
  },
  {
    path: "/Search",
    name: "Search",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Search.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 32,
    },
  },
  {
    path: "/Ceshi",
    name: "Ceshi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Ceshi.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 33,
    },
  },
  {
    path: "/JuniorMilitaryAcademy",
    name: "JuniorMilitaryAcademy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/JuniorMilitaryAcademy.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 34,
    },
  },
  {
    path: "/JuniorActInfo",
    name: "JuniorActInfos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorActInfo.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 35,
    },
  },
  {
    path: "/ActBaomingJun",
    name: "ActBaomingJun",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ActBaomingJun.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 36,
    },
  },
  {
    path: "/Shenbao",
    name: "Shenbao",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/Shenbao.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 37,
      keepTop: true,
    },
  },
  {
    path: "/HostAct",
    name: "HostAct",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/HostAct.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 38,
    },
  },
  {
    path: "/HostAct0",
    name: "HostAct0",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/HostAct0.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 380,
    },
  },
  {
    path: "/HostAct2",
    name: "HostAct2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/HostAct2.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 382,
    },
  },
  {
    path: "/Shenbao1",
    name: "Shenbao1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/Shenbao1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 39,
      keepTop: true,
    },
  },
  {
    path: "/Shenbao2",
    name: "Shenbao2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/Shenbao2.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 40,
      keepTop: true,
    },
  },
  {
    path: "/Shenbao3",
    name: "Shenbao3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/Shenbao3.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 41,
      keepTop: true,
    },
  },
  {
    path: "/Snkxy",
    name: "Snkxy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Snkxy.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 42,
    },
  },
  {
    path: "/redRead",
    name: "redRead",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/redRead.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 43,
    },
  },
  {
    path: "/redChildrenlisten",
    name: "redChildrenlisten",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/redChildrenlisten.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 44,
    },
  },
  {
    path: "/ReadBook",
    name: "ReadBook",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ReadBook.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 45,
      keepTop: true,
    },
  },
  {
    path: "/SnShenbao1",
    name: "SnShenbao1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/SnShenbao1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 46,
      keepTop: true,
    },
  },
  {
    path: "/SnShenbao2",
    name: "SnShenbao2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/SnShenbao2.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 47,
      keepTop: true,
    },
  },
  {
    path: "/ActInfojun",
    name: "ActInfojun",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ActInfojun.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 48,
    },
  },
  {
    path: "/InterInfoOnther",
    name: "InterInfoOnther",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/InterInfoOnther.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 49,
      keepTop: true,
    },
  },
  {
    path: "/gzShenbao1",
    name: "gzShenbao1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/gzShenbao1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 50,
      keepTop: true,
    },
  },
  {
    path: "/gzShenbao2",
    name: "gzShenbao2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/gzShenbao2.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 51,
      keepTop: true,
    },
  },
  {
    path: "/gzShenbao3",
    name: "gzShenbao3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/gzShenbao3.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 52,
      keepTop: true,
    },
  },
  {
    path: "/yShenbao1",
    name: "yShenbao1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/yShenbao1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 53,
      keepTop: true,
    },
  },
  {
    path: "/yShenbao2",
    name: "yShenbao2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/yShenbao2.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 54,
      keepTop: true,
    },
  },
  {
    path: "/SnShenbao3",
    name: "SnShenbao3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/SnShenbao3.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 55,
      keepTop: true,
    },
  },
  {
    path: "/yShenbao3",
    name: "yShenbao3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/yShenbao3.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 56,
      keepTop: true,
    },
  },
  {
    path: "/Actshenbao",
    name: "Actshenbao",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/Actshenbao.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 57,
    },
  },
  {
    path: "/actShenbao1",
    name: "actShenbao1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actShenbao1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 58,
      keepTop: true,
    },
  },
  {
    path: "/rongyuqiang",
    name: "rongyuqiang",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AddactShenbao1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 59,
    },
  },
  {
    path: "/LianboListen",
    name: "LianboListen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LianboListen.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 60,
    },
  },
  {
    path: "/Beidaihe",
    name: "Beidaihe",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Beidaihe.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 61,
      keepTop: true,
    },
  },
  {
    path: "/actShenbao2",
    name: "actShenbao2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actShenbao2.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 62,
      keepTop: true,
    },
  },
  {
    path: "/actShenbao3",
    name: "actShenbao3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actShenbao3.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 63,
      keepTop: true,
    },
  },
  // 子活动
  {
    path: "/actChildShenbao1",
    name: "actChildShenbao1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actChildShenbao1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 64,
      keepTop: true,
    },
  },
  {
    path: "/actChildShenbao2",
    name: "actChildShenbao2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actChildShenbao2.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 65,
      keepTop: true,
    },
  },
  {
    path: "/actChildShenbao2other",
    name: "actChildShenbao2other",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actChildShenbao2other.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 66,
      keepTop: true,
    },
  },

  {
    path: "/actChildShenbao3",
    name: "actChildShenbao3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actChildShenbao3.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 67,
      keepTop: true,
    },
  },

  {
    path: "/AddactShenbao1",
    name: "AddactShenbao1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AddactShenbao1.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 68,
      keepTop: true,
    },
  },
  {
    path: "/AddactShenbao2",
    name: "AddactShenbao2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AddactShenbao2.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 69,
      keepTop: true,
    },
  },
  {
    path: "/AddactShenbao3",
    name: "AddactShenbao3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AddactShenbao3.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 70,
      keepTop: true,
    },
  },
  {
    path: "/SnShenbao2Look",
    name: "SnShenbao2Look",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/SnShenbao2Look.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 71,
      keepTop: true,
    },
  },
  {
    path: "/yShenbao2Look",
    name: "yShenbao2Look",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/yShenbao2Look.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 72,
      keepTop: true,
    },
  },
  {
    path: "/Shenbao2Look",
    name: "Shenbao2Look",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/Shenbao2Look.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 73,
      keepTop: true,
    },
  },
  {
    path: "/gzShenbao2Look",
    name: "gzShenbao2Look",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/gzShenbao2Look.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 74,
      keepTop: true,
    },
  },
  {
    path: "/actFb",
    name: "actFb",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/actFb.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 75,
      keepTop: true,
    },
  },
  {
    path: "/Course",
    name: "Course",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Course.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 76,
    },
  },
  {
    path: "/CourseMore",
    name: "CourseMore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/CourseMore.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 77,
      keepTop: true,
    },
  },
  {
    path: "/CourseDetails",
    name: "CourseDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/CourseDetails.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 78,
      keepTop: true,
    },
  },
  {
    path: "/SnShenbao2onther",
    name: "SnShenbao2onther",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/SnShenbao2onther.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 79,
      keepTop: true,
    },
  },
  {
    path: "/yShenbao2onther",
    name: "yShenbao2onther",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/yShenbao2onther.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 80,
      keepTop: true,
    },
  },
  {
    path: "/actShenbao2other",
    name: "actShenbao2other",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actShenbao2other.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 81,
      keepTop: true,
    },
  },
  {
    path: "/actShenbao2Look",
    name: "actShenbao2Look",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/actShenbao2Look.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 82,
      keepTop: true,
    },
  },
  {
    path: "/AddactShenbao2onther",
    name: "AddactShenbao2onther",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AddactShenbao2onther.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 83,
      keepTop: true,
    },
  },
  {
    path: "/Shenbao2onther",
    name: "Shenbao2onther",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/Shenbao2onther.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 84,
      keepTop: true,
    },
  },
  {
    path: "/gzShenbao2onther",
    name: "gzShenbao2onther",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/gzShenbao2onther.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 85,
      keepTop: true,
    },
  },
  {
    path: "/LoginNext3Onther",
    name: "LoginNext3Onther",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LoginNext3Onther.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 86,
      keepTop: true,
    },
  },
  {
    path: "/oneDayRead",
    name: "oneDayRead",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/oneDayRead.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 87,
      keepTop: true,
    },
  },
  {
    path: "/CenterU",
    name: "CenterU",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/CenterUnderselection/CenterU.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 88,
      keepTop: true,
    },
  },
  {
    path: "/CenterUDetails",
    name: "CenterUDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/CenterUnderselection/CenterUDetails.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 89,
      keepTop: true,
    },
  },
  {
    path: "/MyScore",
    name: "MyScore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/MyScore.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 90,
    },
  },
  {
    path: "/MyCourse",
    name: "MyCourse",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/MyCourse.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 91,
    },
  },
  {
    path: "/MyHonor",
    name: "MyHonor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/MyHonor.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 92,
    },
  },
  {
    path: "/MyAccountMan",
    name: "MyAccountMan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/MyAccountMan.vue"),
    meta: {
      keepAlive: true,
      keepAliveKey: 93,
    },
  },
  {
    path: "/MySignin",
    name: "MySignin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/my/MySignin.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 94,
    },
  },
  {
    path: "/PxInfojun",
    name: "PxInfojun",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/PxInfojun.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 95,
    },
  },
  {
    path: "/JinxiuInfo",
    name: "JinxiuInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JinxiuInfo.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 96,
    },
  },
  {
    path: "/SheshiInfo",
    name: "SheshiInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/SheshiInfo.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 96,
    },
  },
  {
    path: "/CourseChildDetails",
    name: "CourseChildDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/CourseChildDetails.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 97,
      keepTop: true,
    },
  },
  {
    path: "/readInfo",
    name: "readInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/read/readInfo.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 98,
      keepTop: true,
    },
  },
  {
    path: "/togetherRead",
    name: "togetherRead",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/read/togetherRead.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 98,
      keepTop: true,
    },
  },
  {
    path: "/jcDetails",
    name: "jcDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/JuniorMA/jcDetails.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 99,
    },
  },
  {
    path: "/yingdiActivity",
    name: "yingdiActivity",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/yingdi/yingdiActivity.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 100,
    },
  },
  {
    path: "/ActBaomingOffline",
    name: "ActBaomingOffline",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ActBaomingOffline.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 101,
    },
  },
  {
    path: "/ActBaomingSub",
    name: "ActBaomingSub",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ActBaomingSub.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 101,
    },
  },
  {
    path: "/ScienceQa",
    name: "ScienceQa",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ScienceQa.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 101,
    },
  },
  {
    path: "/player",
    name: "player",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/player/index.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 102,
    },
  },
  {
    path: "/player/info",
    name: "playerInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/player/detail/index.vue"),
    meta: {
      keepAlive: false,
      keepAliveKey: 102,
    },
  },
];

// createWebHashHistory
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const position: any = {};
    if (to.matched.some((m) => m.meta.keepTop)) {
      position.x = 0;
      position.y = 0;
    }
    return position;
  },
});
router.beforeEach((to, from, next) => {
  // console.log(store.state.routerArrLength)
  // if (store.state.routerArrLength.length === 0) {
  //   store.commit("routerArr", to.name);
  //   // (window as any).scrollTo(0, 0)
  // } else {
  //   var name = to.name
  //   console.log(store.state.routerArrLength,'store.state.routerArrLength')
  //   if (store.state.routerArrLength.indexOf(name) === -1) {
  //   console.log(store.state.routerArrLength,'1')

  //     store.commit("routerArr", to.name);
  //     // (window as any).scrollTo(0, 0)
  //   } else {
  //     console.log(store.state.routerArrLength,'2')
  //   }
  // }
  // if(to.meta.keepTop===true){
  //   (window as any).scrollTo(0, 0)
  // }
  if (to.name === "login") {
    if (from.name === "phone") {
    } else {
      store.commit("routerLength", from.name);
      console.log(from.query);
      if (from.query.id) {
        store.commit("routerLengthNext", from.query.id);
      }
    }
  }
  if (from.name === "Lianbo" && to.name === "LianboInfo") {
    from.meta.keepAlive = true;
  } else if (from.name === "Lianbo" && to.name === "Index") {
    from.meta.keepAlive = false;
  } else if (from.name === "Index" && to.name === "Lianbo") {
    to.meta.keepAlive = false;
  } else if (from.name === "LianboInfo" && to.name === "Lianbo") {
    to.meta.keepAlive = true;
  }

  next();
});
export default router;
