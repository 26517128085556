import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-679be1fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_overlay = _resolveComponent("van-overlay")!

  return (_openBlock(), _createBlock(_component_van_overlay, {
    "z-index": "9999999999",
    show: _ctx.state.showOverlay,
    onClick: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "img",
        src: _ctx.imgUrl
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["show", "onClick"]))
}