
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "CButton",
  props: {},
  setup() {
    const state = reactive({
      year:
        new Date().getFullYear() == 2023
          ? "2023"
          : "2023-" + new Date().getFullYear(),
    });
    return {
      state,
    };
  },
  methods: {},
  mounted() {},
});
