
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  nextTick,
  onMounted,
  ref,
} from "vue";

import { getUserlist } from "@/http/api"; //接口
import { useRouter } from "vue-router";
// import request from "../http/axios";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
export default defineComponent({
  name: "HomeView",
  // components: {
  //   HelloWorld,
  // },
  setup() {
    let internalInstance: any = getCurrentInstance(); // 有效
    const box1: any = require("../assets/login_bc.jpg"); // eslint-disable-line
    const box2: any = require("../assets/password.png"); // eslint-disable-line
    const box3: any = require("../assets/username.png"); // eslint-disable-line
    const box4: any = require("../assets/wx.png"); // eslint-disable-line
    const checked = ref(false);
    const state = reactive({
      drawerVisible: false,
      drawerLeftVisible: false,
      showindexAll: false,
      changeImage: [box1, box2, box3, box4],
      userename: "",
      password: "",
    });
    const $router = useRouter();
    //点击方法
    const urlClick = () => {
      $router.push({ path: "/case" });
    };
    const urlClick1 = (item: any) => {
      $router.push({ path: "/caseinfo", query: { id: item.id } });
    };
    const urlClick2 = (item: any) => {
      $router.push({ path: "/Velue" });
    };
    const checkAll = () => {
      console.log(checked);
    };
    onMounted(() => {});
    return {
      state,
      checked,
      urlClick,
      urlClick1,
      urlClick2,
      internalInstance,
      checkAll,
    };
  },

  methods: {},
  async mounted() {},
});
