<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component
        :is="Component"
        v-if="route.meta.keepAlive"
        :key="route.meta.keepAliveKey"
      />
    </keep-alive>
    <component :is="Component" v-if="!route.meta.keepAlive" />
  </router-view>
</template>
<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  nextTick,
  onMounted,
  ref,
} from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    return {
      $router,
      $route,
    };
  },
});
</script>

<style lang="less">
@import "vant/lib/index.css";
body,
html {
  // width: 100%;
  // max-width: 750PX;
  padding: 0;
  margin: auto 0;
  min-width: 320px;
  // max-width: 750px;
  // margin: 0 auto;
  // height: 2000px;
  // line-height: 1.5;
  // font-family: Arial, Helvetica;
  // background-color: #F2f2f2;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // width: 750PX;
  // width: 100%;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.it-drawer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 999999;
}
// video::-webkit-media-controls {
//   display: none !important;
// }
// 隐藏video 音量按钮
// video::-webkit-media-controls-mute-button {
//   display: none !important;
// } // 隐藏video 当前按钮
// video::-webkit-media-controls-current-time-display {
//   display: none !important;
// } // 隐藏video 总时间
// video::-webkit-media-controls-time-remaining-display {
//   display: none !important;
// } // 隐藏video 全屏按钮
// video::-webkit-media-controls-fullscreen-button {
//   display: none !important;
// }
</style>
