import { createStore } from "vuex";
import {
  getUserlist,
  getUserlistFrom,
  getUserlisttype,
  getUserlisttypeUrl,
  getUserlisttype1,
  getUserlisttype2,
} from "@/http/api"; //接口
import { showToast ,closeToast} from "vant";
export default createStore({
  state: {
    lisstDaat: [],
    AxiosData: [],
    count: 0,
    score: 0,
    homeScroll: 0,
    jgInfoImg: {},
    KsInfoImg: {},
    snInfoData: {},
    ygInfoData: {},
    yInfoData: {},
    yhInfoDat: {},
    y2hInfoDat: {},
    routerName: "/",
    routerNameNext: "",
    routerArrLength: [] as any,
  },
  getters: {},
  mutations: {
    sumAxios(state, data) {
      state.AxiosData = data;
      // state.count += num
    },
    sun(state, data) {
      getUserlist(data).then((res: any) => {
        if (res.data) {
          if (res.data.status === 1) {
            state.AxiosData = res.data;
          } else {
            showToast(res.data.msg);
          }
        } else {
          showToast(res.data.msg);
        }
      });
    },
    jgInfoData(state, data) {
      state.jgInfoImg = data;
    },
    KsInfoData(state, data) {
      state.KsInfoImg = data;
    },
    SnInfoData(state, data) {
      state.snInfoData = data;
    },
    YgInfoDat(state, data) {
      state.ygInfoData = data;
    },
    YInfoDat(state, data) {
      state.yInfoData = data;
    },
    YhInfoDat(state, data) {
      state.yhInfoDat = data;
    },
    Y2hInfoDat(state, data) {
      state.y2hInfoDat = data;
    },
    routerLength(state, data) {
      console.error("登录前地址", data);
      state.routerName = data;
    },
    routerLengthNext(state, data) {
      console.error("登录前地址Next", data);
      state.routerNameNext = data;
    },
    routerArr(state, data) {
      state.routerArrLength.push(data);
    },
    setScore(state, data) {
      state.score = data;
    },
    setHomeScroll(state, data) {
      state.homeScroll = data;
    },
  },
  actions: {
    sumactions(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlist(data).then((res: any) => {
          console.log(res);
          if (res.data) {
            if (res.data.status === 1) {
              Content.commit("sumAxios", res.data);
              resolve(res.data); // 通知组件，接口调用成功了
            } else if (Number(res.data.status) === -1) {
              showToast("用户未登录");
              reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
            } else {
              reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
              showToast(res.data.msg);
            }
          } else {
            if (res.msg) {
              showToast(res.msg);
            } else if (res.data.msg) {
              showToast(res.data.msg);
            }
          }
        });
      });
    },
    sumactions1(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlist(data).then((res: any) => {
          console.log(res);

          if (res.data.status === 1) {
            resolve(res.data); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.status === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          } else {
            showToast(res.data.msg); // 通知组件，接口调用失败，并把失败原因传过去
          }
        });
      });
    },
    sumactions2(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlistFrom(data).then((res: any) => {
          console.log(res);

          if (res.data.status === 1) {
            resolve(res); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.status === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
          } else {
            reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          }
        });
      });
    },
    sumactions3(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlisttype(data.data, data.type).then((res: any) => {
          console.log(res);

          // if (res.data.status === 1) {
          //   resolve(res) // 通知组件，接口调用失败，并把失败原因传过去
          // } else if (res.data.status === -1) {
          //   reject("登录") // 通知组件，接口调用失败，并把失败原因传过去
          // } else {
          //   reject("false") // 通知组件，接口调用失败，并把失败原因传过去
          //   showToast(res.data.msg);
          // }

          if (res.data.code == 0) {
            resolve(res); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.code === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
          } else {
            reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          }
        });
      });
    },
    sumactions5(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlisttype1(data.data, data.type).then((res: any) => {
          console.log(res);

          if (res.data.code === 0) {
            resolve(res); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.code === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
          } else {
            reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          }
        });
      });
    },
    sumactions52(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlisttype1(data.data, data.type).then((res: any) => {
          console.log(res);
          if (res.data.code === 0) {
            resolve(res); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.code === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
          } else {
            reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          }
        });
      });
    },
    sumactions51(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlisttype1(data.data, data.type).then((res: any) => {
          console.log(res.data.data);
          const dataReg = res.data.data.data;
          const regNextData = [];
          for (let i = 0; i < dataReg.length; i++) {
            regNextData.push({
              activity_type: dataReg[i].activity_project_id,
              cid: dataReg[i].cid,
              click: dataReg[i].click,
              content: dataReg[i].content,
              create_time: dataReg[i].create_time,
              description: dataReg[i].decription,
              detail_pic: dataReg[i].detail_pic,
              good: dataReg[i].good,
              id: dataReg[i].id,
              index_pic: dataReg[i].cover,
              is_index: dataReg[i].is_index,
              jump_url: dataReg[i].jump_url,
              latitude: dataReg[i].latitude,
              longitude: dataReg[i].longitude,
              source: dataReg[i].source,
              status: dataReg[i].status,
              title: dataReg[i].title,
              video: dataReg[i].video,
            });
          }
          const dataGo = res.data.data;
          const datatihs = {
            data: dataGo,
          };
          dataGo.list = regNextData;
          if (res.data.code === 0) {
            resolve(datatihs); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.code === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
          } else {
            reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          }
        });
      });
    },
    sumactions7(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlisttype1(data.data, data.type).then((res: any) => {
          console.log(res);

          if (res.data.code === 0) {
            resolve(res); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.code === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
          } else {
            reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          }
        });
      });
    },
    sumactions6(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlisttype2(data.data, data.type).then((res: any) => {
          console.log(res, "11111");

          if (res.data.code === 0) {
            resolve(res); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.code === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
          } else {
            reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          }
        });
      });
    },
    sumactions4(Content, data) {
      return new Promise((resolve, reject) => {
        getUserlisttypeUrl(data.data, data.type, data.url).then((res: any) => {
          console.log(res);

          if (res.data.status === 1) {
            resolve(res); // 通知组件，接口调用失败，并把失败原因传过去
          } else if (res.data.status === -1) {
            reject("登录"); // 通知组件，接口调用失败，并把失败原因传过去
          } else {
            reject("false"); // 通知组件，接口调用失败，并把失败原因传过去
            showToast(res.data.msg);
          }
        });
      });
    },
  },
  modules: {},
});
